import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from 'vue-meta-info'
import 'swiper/css/swiper.css'
import '../public/styles/reset.css'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import setAxios from '@/setAxios'

Vue.use(MetaInfo)
Vue.config.productionTip = false

setAxios()

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
