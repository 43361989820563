/**
 * @description axios拦截器
 * @author YouRan
 */
import axios from 'axios'
import { TOKEN } from '@/utils/requestApi'

export default function setAxios () {
  // 请求拦截器
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `${TOKEN}`
    return config
  }, error => Promise.error(error))
  // 响应拦截器
  axios.interceptors.response.use(response => response.data, error => Promise.reject(error))
}
