/**
 * @description 用于存放公共请求地址
 * @author YouRan
 */
// token -> headers: {Authorization：'1a5394e61ca8b473973cef0b6f79b195' }
const TOKEN = '1a5394e61ca8b473973cef0b6f79b195'
// 官网测试地址 http://test.m.daxinbuye.com
// const host = 'http://api.daxinbuye.com/test'
// const hostApi = host + '/offical'

// const host = 'http://192.168.2.200:19000'
// const hostApi = host + '/offical'

// 线上环境
const host = 'https://api.daxinbuye.com'
const hostApi = host + '/offical'

module.exports = {
  host,
  hostApi,
  TOKEN
}
